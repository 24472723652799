export default function addElement(e) {
    e.stopPropagation();
    let addDiv = document.createElement('div'),
        mValue = Math.max(this.clientWidth, this.clientHeight),
        rect = this.getBoundingClientRect();
    let sDiv = addDiv.style, px = 'px';

    sDiv.width = sDiv.height = mValue + px;
    sDiv.left = e.clientX - rect.left - mValue / 2 + px;
    sDiv.top = e.clientY - rect.top - mValue / 2 + px;

    addDiv.classList.add('pulse');
    this.appendChild(addDiv);
    setTimeout(()=> {
        addDiv.remove();
    }, 700)
}