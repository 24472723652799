
import Choices from 'choices.js';

const map = new WeakMap();

function getInstanceByElement(element) {
    return map.get(element);
}

function init(container) {
    const elements = Array.from(container.querySelectorAll('.js-select'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            const choices = new Choices(el, {
                searchEnabled: el.classList.contains('js-select-search'),
                itemSelectText: '',
                shouldSort: false,
                position: 'bottom',
                noResultsText: 'Ничего не найдено'
            });
            map.set(el, choices);
        });
    }
}

function destroy(container) {
    const elements = Array.from(container.querySelectorAll('.js-select'));
    elements.forEach((el) => {
        const choices = getInstanceByElement(el);
        if (choices) {
            choices.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;