export default function Tab(container){
    let currentTab = container.querySelector('.js-tab-content.is-in'),
        links = Array.from(container.querySelectorAll('.js-tab-control .js-tab-link')),
        tabs = Array.from(container.querySelectorAll('.js-tab-content')),
        currentLink = container.querySelector('.js-tab-link.is-in'),
        oldLink = currentLink,
        oldTab = currentTab;
        if (window.location.hash !== '') {
            tabs.forEach(tab => tab.classList.remove('is-in'));
            links.forEach(link => link.classList.remove('is-in'));
            currentTab = document.querySelector(window.location.hash);
            currentLink = document.querySelector(`a[href="${window.location.hash}"]`)
            currentTab.classList.add('is-in');
            currentLink.classList.add('is-in');
        }
    links.map(function(link){
        link.addEventListener('click', function(e) {
            e.preventDefault();
            let hash = this.getAttribute('href');
            history.replaceState({}, '', hash);
            oldLink = currentLink;
            currentLink = this;
            oldLink?.classList.remove('is-in');
            currentLink?.classList.add('is-in');
            oldTab = currentTab;
            currentTab = container.querySelector(hash);
            oldTab?.classList.remove('is-in');
            oldTab?.classList.add('is-out');
            oldTab?.classList.remove('is-out')
            currentTab?.classList.add('is-in');
        })
    })
}