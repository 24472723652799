/* global USE_SERVICE_WORKER */
import './webpack-imports';
import './sentry';
import './polyfills';
import Tab from './components/tab';
import { onFocus, onBlur, onReady } from './components/input-focus';
import Accordeon from './components/accordeon';
import Modal from './components/modal';
import axios from 'axios';
import delegate from 'delegate';
import IMask from 'imask';
import fileLoad from './components/fileLoad';
import selects from './inits/selects';
import Swiper ,{ Navigation } from 'swiper';
import addElement from './components/ripple';
import baguetteBox from 'baguettebox.js';
import validate from './components/validate'
import ajaxForms from './inits/ajax-forms';
import { registerCustomElements } from './custom-elements';

import LazyLoad from 'vanilla-lazyload';
import smoothscroll from 'smoothscroll-polyfill';

const preloaderBar = document.querySelector('.preloader__bar');
const preloaderShade = document.querySelector('.preloader__shade');
const preloader = document.querySelector('.preloader');
const preloaderLogo = document.querySelector('.preloader__logo');

let delayForPerf = 250;
let delayAnimPreload = 640;
let delay = delayForPerf + delayAnimPreload*2;
if (sessionStorage.loaded !== 'true') {
    preloader?.classList.remove('is-visited');
    delay = delayForPerf + delayAnimPreload*2;
}

var lazyLoadInstance = new LazyLoad({
    callback_loaded: function() {
        let percentOfLoad = parseInt((1 - (lazyLoadInstance.loadingCount*2 / lazyLoadInstance.toLoadCount)) * 100);
        preloaderBar.style.transform = `scale3d(${percentOfLoad/100}, 1, 1)`;
    },
});

if (lazyLoadInstance.loadingCount == 0) {
    preloaderBar.style.transform = `scale3d(1, 1, 1)`;
}

function hideOnReady() {
    if (lazyLoadInstance.loadingCount == 0) {
        setTimeout(()=> {
            preloaderShade?.classList.add('is-in');
            preloaderLogo?.classList.add('is-in');
            setTimeout(()=> {
                preloader?.classList.add('is-in')
                sessionStorage.setItem('loaded', true);

                revealInit();
                hidePageOnOuter();
            }, delayAnimPreload)
        }, delayForPerf)
        setTimeout(()=> {
            preloader.style.display = 'none';
        },delay)
    }
}

hideOnReady();


function revealInit() {
    const revealItemsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                revealItemsObserver.unobserve(entry.target);
                entry.target.classList.add('revealed');
            }
        });
    });

    Array.from(document.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.observe(el);
    });
}



const sertsSlider = document.querySelector('.js-serts-slider') as HTMLElement | null;

if (sertsSlider) {
    setTimeout(()=> {
        initSertsSlider();
    },100)
}

function initSertsSlider() {
    var mySwiper = new Swiper(sertsSlider, {
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    })
}


// Forces repaint, use when really needed.
// document.documentElement.classList.add('js-ready');

registerCustomElements();

// Code here

Swiper.use(Navigation);

selects.init(document)

const disabledSelects = Array.from(document.querySelectorAll('.js-select.is-disabled'));
disabledSelects.forEach(item => selects.getInstanceByElement(item).disable());


const buttons = Array.from(document.querySelectorAll('.btn'));
buttons.forEach(item => item.addEventListener('mousedown', addElement));

const tabs = Array.from(document.querySelectorAll('[data-tabs]'));
tabs.forEach((tab) => {
    tab = new Tab(tab);
});

const formsWithFiles = Array.from(document.querySelectorAll('.js-form-with-file'));
formsWithFiles.forEach(form => {
    form = new fileLoad(form);
})

const inputs = Array.from(document.querySelectorAll('.js-input-focus'));

inputs.map(item => {
    item.addEventListener('focus', onFocus);
    item.addEventListener('blur', onBlur);
    onReady(item);
});

delegate('[data-accordeon]', 'click', function(e) {
    e.delegateTarget = new Accordeon(e.delegateTarget);
}, false);

const eye = Array.from(document.querySelectorAll('.js-eye'));

eye?.map(item => {
    item.addEventListener('click', function() {
        let input = this.parentNode.querySelector('input');
        input.getAttribute('type') == 'password' ? input.setAttribute('type', 'text') : input.setAttribute('type', 'password');
    })
})

const articlesSelector = document.querySelector('.article-slider');

if (articlesSelector) {
    var mySwiper = new Swiper(articlesSelector, {
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.article-slider__arrow--next',
            prevEl: '.article-slider__arrow--prev',
        },
        slidesOffsetAfter: 60
    })
}

const detalSliderSelector = Array.from(document.querySelectorAll('.js-detail-slider'));

if (detalSliderSelector.length > 0) {
    var mySwiper = new Swiper(detalSliderSelector, {
        slidesPerView: 1,
        spaceBetween: 60,
        navigation: {
            nextEl: '.detail__arrow--next',
            prevEl: '.detail__arrow--prev',
        }
    })
}

const links = Array.from(document.querySelectorAll('[data-drop-toggle]'));

links.forEach(link => {
    link = new Modal(link);
});

const newValues = document.querySelector('.js-new-values');
delegate('.js-form-add-value', 'submit', function(e) {
    e.preventDefault();
    let input = e.target.querySelector('.input--number');

    if (input.value == '' || input.value < 0) {
        input.classList.add('is-error');
        return
    }
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    input.classList.remove('is-error')
    const message = e.target.querySelector('.js-form-message');

    let formData = new FormData(e.target);
    axios.post(e.target.getAttribute('action'), formData, {headers})
    .then(res => {
        message.innerHTML = res.data.message;
        e.target.classList.add('is-message');
        if (res.data.success) {
            e.target.classList.remove('is-load');
            e.target.classList.add('is-lock');
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
                setTimeout(() => {
                    e.target.classList.remove('is-lock');
                }, 640)
            }, 2000)
            let row = document.createElement('tr');
            row.classList.add('a-fade-translate');
            row.classList.add('revealed');
            row.innerHTML = res.data.data.html;
            newValues?.prepend(row);
            input.value = '';
        } else {
            e.target.classList.remove('is-load');
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
            }, 2000)
        }
    })
    .catch(res => {
        console.log(res);
    });
}, false);

delegate('.js-form-change-value', 'submit', function(e) {
    e.preventDefault();

    let input = e.target.querySelector('.input--number');
    let oldValueEl = e.target.parentNode.querySelector('.js-old-value');
    const message = e.target.querySelector('.js-form-message');
    if (input.value == '' || input.value < 0) {
        input.classList.add('is-error');
        return
    }

    input.classList.remove('is-error');

    e.target.classList.add('is-load');
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    let formData = new FormData(e.target);
    axios.post(e.target.getAttribute('action'), formData, {headers})
    .then(res => {
        e.target.classList.add('is-message');
        message.innerHTML = res.data.message;
        if (res.data.success) {
            e.target.classList.remove('is-load');
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
                setTimeout(() => {
                    e.target.classList.remove('is-lock');
                }, 640)
            }, 2000)
            oldValueEl.innerHTML = input.value;
            input.value = '';
        } else {
            e.target.classList.remove('is-load');
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
            }, 2000)
        }
    })
    .catch(res => {
        console.log(res);
    });
}, false);

delegate('.js-form-change-email', 'submit', function(e) {
    e.preventDefault();
    const emailPlace = document.querySelector('.js-email-placeholder');
    const emailSetLink = document.querySelector('.js-change-email-link');
    const subscribeBlock = document.querySelector('.js-subscribe-email');
    const message = e.target.querySelector('.js-form-message');

    let input = e.target.querySelector('input[type="text"]');

    if (!/^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i.test(input.value)) {
        input.classList.add('is-error');
        return
    }
    e.target.classList.add('is-load');
    e.target.querySelector('input[type="text"]').classList.remove('is-error');
    let formData = new FormData(e.target);
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    axios.post(e.target.getAttribute('action'), formData, {headers})
    .then(res => {
        message.innerHTML = res.data.message;
        e.target.classList.add('is-message');
        if (res.data.success) {
            e.target.classList.remove('is-load');
            emailPlace.classList.add('has-content')
            emailPlace.innerHTML = res.data.data.email;
            emailSetLink.innerHTML = 'Изменить';
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
                setTimeout(() => {
                    subscribeBlock?.classList.add('is-open');
                }, 640)
            }, 2000)
        } else {
            e.target.classList.remove('is-load');
            setTimeout(function() {
                e.target.classList.remove('is-message');
                window.hide(e.target.id);
            }, 2000)
        }
    })
    .catch(res => {
        console.log(res);
    });
}, false);

delegate('.js-subscribe-email-checkbox', 'change', function(e) {
	e.preventDefault();
	const form = document.querySelector('.js-subscribe-email-form');
	const data = new FormData(form);
	form?.classList.add('is-load');
	form?.classList.add('is-lock');
	const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };

	axios.post(form.getAttribute('action'), data, {headers})
	.then(res => {
		form?.classList.remove('is-lock');
		form?.classList.remove('is-load');
	})
	.catch(res => {
		console.log(res);
	});
}, false);

delegate('.js-load-payments', 'submit', function(e) {
    e.preventDefault();
    console.log(this);
    console.log(e);
    console.log(e.delegateTarget);
    console.log(e.delegateTarget.closest('.tab-control'));

    const newPaymentsPlace = e.delegateTarget.closest('.tab-content').querySelector('.js-new-payments');
    const errorMessage = document.querySelector('.js-load-payments--error');
    e.target.classList.add('is-load');
    errorMessage?.classList.remove('is-show');

    const data = new FormData(e.target);
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    axios.post(e.target.getAttribute('action'), data, {headers})
    .then(res => {
        if (res.data.success) {
            e.target.classList.remove('is-load');
            newPaymentsPlace.insertAdjacentHTML('beforeend', res.data.data.html);
            if (res.data.data.page) {
                e.target.setAttribute('action', e.target.dataset.actionBase + res.data.data.page);
            }
            if (res.data.data.isLast) {
                e.target.remove();
            }
        } else {
            e.target.classList.remove('is-load');
            errorMessage.classList.add('is-show');
        }
    })
    .catch(res => {
       console.log(res);
    });
})

const currentHouse = {
    street: '',
    house: ''
};
function findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}
delegate('.js-street-select', 'change', function(e) {
    const housesSelects = Array.from(document.querySelectorAll('.js-house-select'));
    const streetsSelects = Array.from(document.querySelectorAll('.js-street-select'));
    const curStreetId = e.target.value;

    let form = findAncestor(e.target, 'js-select-form');

    const headers = { 'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest' };

    const data = new FormData(form);
    data.append('action', e.target.dataset.action);
    currentHouse.street = curStreetId;
    currentHouse.house = '';

    let firstPlaceholder = document.querySelector('.js-my-home-first-placeholder');
    // let secondPlaceholder = document.querySelector('.js-my-home-second-placeholder');

    firstPlaceholder.innerHTML = '';
    // secondPlaceholder.innerHTML = '';

    axios.post(form.action, data, {headers})
    .then(res => {
        streetsSelects.forEach(select => {
            const streetSelectInstance = selects.getInstanceByElement(select);
            streetSelectInstance.setChoiceByValue(currentHouse.street);
        })
        housesSelects.forEach(select => {
            let options = [{value: '', label: 'Дом', placeholder: true, selected: true}];
            // let options = [''];
            res.data.data.houses.forEach(item => {
                let option = { value: item.value, label: item.name };
                options.push(option);
            })
            const houseSelectInstance = selects.getInstanceByElement(select);
            houseSelectInstance.enable();
            houseSelectInstance.clearChoices();
            houseSelectInstance.setChoices(options, 'value', 'label', true);
        });
        revealInit();
        hidePageOnOuter();
    })
    .catch(res => {
        console.log(res);
        e.target.classList.remove('is-load');
    })
});

delegate('.js-house-select', 'change', function(e) {
    const housesSelects = Array.from(document.querySelectorAll('.js-house-select'));
    const streetsSelects = Array.from(document.querySelectorAll('.js-street-select'));
    const loader = document.getElementById('loader');
    loader?.classList.add('is-open');
    const curHouseId = e.target.value;
    let form = findAncestor(e.target, 'js-select-form');

    const data = new FormData(form);
    data.append('action', e.target.dataset.action);

    currentHouse.house = curHouseId;

    const firstSection = document.querySelector('.js-first-section-my-home');
    let firstPlaceholder = document.querySelector('.js-my-home-first-placeholder');

	let hiddenSections = document.querySelectorAll('.js-hidden-section');
    const headers = {'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'};

    axios.post(form.action, data, {headers})
    .then(res => {
        loader?.classList.remove('is-open');
        if (res.data.success) {
            firstSection?.remove();
            firstPlaceholder.innerHTML = res.data.data.html;

			hiddenSections.forEach(block => {
				block.classList.remove('section--hide');
            })
            streetsSelects.forEach(select => {
                const streetSelectInstance = selects.getInstanceByElement(select);
                streetSelectInstance.setChoiceByValue(currentHouse.street);
            })
            housesSelects.forEach(select => {
                const houseSelectInstance = selects.getInstanceByElement(select);
                if (currentHouse.house !== '' && houseSelectInstance) {
                    let id = currentHouse.house;
                    if (typeof currentHouse.house == 'number') {
                        id = parseInt(currentHouse.house, 10);
                    }
                    houseSelectInstance.setChoiceByValue(id);
                }
            })
            revealInit();
            hidePageOnOuter();
        } else {
            console.log(res);
        }
    })
    .catch(res => {
        console.log(res)
        e.target.classList.remove('is-load');
        // errorMessage.classList.add('is-show');
    });
});

delegate('.js-news-pagination', 'click', function(e) {
    e.preventDefault();

    const loader = document.querySelector('.js-news-tab-loader');
    loader?.classList.add('is-open');


    let header = document.querySelector('.header');
    let headerHeight = header.offsetHeight;
    let windowOffset = window.pageYOffset;
    let content = document.querySelector('.js-tab-content');
    let elementOffset = content.getBoundingClientRect().top;
    let scrollPosition = windowOffset - headerHeight + elementOffset - 100;
    window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
    });


    let placeholder = document.querySelector('.js-news-placeholder');

    let data = {};
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    axios.post(e.target.href, data, {headers})
    .then(res => {
		loader?.classList.remove('is-open');

		let placeholder = document.querySelector(res.data.data.selector);
        let str = res.data.data.html;
        placeholder.innerHTML = str;

        revealInit();
        hidePageOnOuter();
        lazyLoadInstance.update();
        let buttons = Array.from(document.querySelectorAll('.btn'));
        buttons.forEach(item => item.removeEventListener('mousedown', addElement));
        buttons.forEach(item => item.addEventListener('mousedown', addElement));
    })
    .catch(res => {
        loader.classList.remove('is-load');
        // errorMessage.classList.add('is-show');
    });
});

delegate('.js-useful-pagination', 'click', function(e) {
    e.preventDefault();

    const loader = document.querySelector('.js-useful-tab-loader');
    loader?.classList.add('is-open');


    let header = document.querySelector('.header');
    let headerHeight = header.offsetHeight;
    let windowOffset = window.pageYOffset;
    let content = document.querySelector('.js-tab-content');
    let elementOffset = content.getBoundingClientRect().top;
    let scrollPosition = windowOffset - headerHeight + elementOffset + 100;
    window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
    });


    let placeholder = document.querySelector('.js-useful-placeholder');

    let data = {};
    const headers = {
        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
    };
    axios.post(e.target.href, data, {headers})
    .then(res => {
        loader?.classList.remove('is-open');

        let str = res.data.data.html4;
        (placeholder as any).innerHTML = str;

        revealInit();
        hidePageOnOuter();
        lazyLoadInstance.update();
        let buttons = Array.from(document.querySelectorAll('.btn'));
        buttons.forEach(item => item.removeEventListener('mousedown', addElement));
        buttons.forEach(item => item.addEventListener('mousedown', addElement));
    })
    .catch(res => {
        console.log(res)
        e.target.classList.remove('is-load');
        // errorMessage.classList.add('is-show');
    });
});

const subscribeCheckboxes = Array.from(document.querySelectorAll('.js-checkbox-subscribe'));

delegate('.js-checkbox-subscribe', 'change', function(e) {
    const target = e.delegateTarget;
    subscribeCheckboxes.forEach(check => check.checked = target.checked);
});


const tels = Array.from(document.querySelectorAll('input[data-mask="tel"]'));

tels?.map(tel => {
    const imask = IMask(tel, { mask: '+{7} (000) 000-00-00' });
});

if (window.UK_ARRAY || window.HOUSES_ARRAY) {
    ymaps.ready(init);
    function init(){

    let zoomValue;

    if (window.matchMedia('max-width: 768px')) {
        zoomValue = 15;
    } else {
        zoomValue = 16;
    }

    let myMap = new ymaps.Map("map", {
        center: [56.03807007267951,92.8719829577026],
        zoom: zoomValue
    });

    window.UK_ARRAY.forEach(item => {
        let mark = new ymaps.Placemark(item.geo, {
            hintContent: item.hint,
            balloonContent: item.baloon
        }, {
            iconLayout: 'default#image',
            iconImageHref: item.main ? `${PUBLIC_PATH}img/pin-red.svg` : `${PUBLIC_PATH}img/pin-blue.svg`,
            iconImageSize: [30, 42],
            iconImageOffset: [-5, -38]
        });
        myMap.geoObjects.add(mark)
    });
    window.HOUSES_ARRAY.forEach(item => {
        let mark = new ymaps.Placemark(item.geo, {
            hintContent: item.hint,
            balloonContent: item.baloon
        }, {
            iconLayout: 'default#image',
            iconImageHref: `${PUBLIC_PATH}img/pin-blue.svg`,
            iconImageSize: [30, 42],
            iconImageOffset: [-5, -38]
        });
        myMap.geoObjects.add(mark)
    });
    myMap.behaviors.disable('scrollZoom');
    }
}

baguetteBox.run('.js-serts-slider');

let header = document.querySelector('.header');
let hero = document.querySelector('.section.hero');
let isHeaderPinned = false;
let timer;

if (window.matchMedia('(min-width: 1025px)').matches) {
    var options = {
        rootMargin: '-80px 0px 0px 0px',
        threshold: [0, 1]
    }
    var callback = function(entries, observer) {
        hero.style.transform = `translateY(${entries[0].boundingClientRect.top*1.5})`;
        if (entries[0].boundingClientRect.top < 80) {
            header.classList.add('is-pinned');
            isHeaderPinned = true;
            header.classList.remove('is-out');
        } else {
            clearTimeout(timer)
            header.classList.add('is-out');
            timer = setTimeout(() => {
                header.classList.remove('is-pinned');
                header.classList.remove('is-out');
                isHeaderPinned = false;
            },300)
        }
    };
    var observer = new IntersectionObserver(callback, options);
    var target = document.querySelector('.js-hero-wrapper');
    if (target) {
        observer.observe(target);
        window.addEventListener('scroll', function(e) {
            hero.style.transform = `translate3d(0,${window.pageYOffset*(0.5)}px, 0)`;
        })
    }
}

const forms = Array.from(document.querySelectorAll('.js-form'));

forms.forEach(form => {
    validate(form);
});


smoothscroll.polyfill();


function hidePageOnOuter() {
    let outerLinks = Array.from(document.querySelectorAll('.js-link-outer')),
    elements = Array.from(document.querySelectorAll('.a-fade-translate')),
    bgElements = Array.from(document.querySelectorAll('.a-bg-section')),
    borderElements = Array.from(document.querySelectorAll('.a-border-section')),
    wysiwyg = document.querySelector('.wysiwyg');

    outerLinks.forEach(link => {
        link.addEventListener('click', function(e) {
            if (!(e.ctrlKey || e.metaKey) && !e.target.dataset.dropToggle) {
                e.preventDefault();
                let url = this.getAttribute('href');

                if (url == document.location.pathname) {
                    return;
                }
                elements.forEach(element => element.classList.add('is-out'));
                bgElements.forEach(element => element.classList.add('is-out'));
                borderElements.forEach(element => element.classList.add('is-out'));
                if (wysiwyg) {
                    wysiwyg.classList.add('is-out');
                }
                setTimeout(function () {
                    document.location.href = url;
                }, 640);
            }
        })
    })
}
