function onFocus(e) {
    this.classList.add('is-fill');
}

function onBlur() {
    if (this.value == '') {
        this.classList.remove('is-fill');
    }
}

function onReady(input) {
    if (input.value !== '') {
        input.classList.add('is-fill')
    } else {
        input.classList.remove('is-fill')
    }
}

export { onFocus, onBlur, onReady };
