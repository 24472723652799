import delegate from 'delegate'

export default function Modal( element ) {
    let API = {},
        setting = {},
        body = document.querySelector('body'),
        html = document.querySelector('html'),
        header = document.querySelector('.header');
    if (!element) {
        return
    }

    function show(event) {
        let elem, link;
        let width = window.innerWidth,
				fullWidth = document.documentElement.clientWidth,
				differenceWidth = (width - fullWidth);
        if (event.target.getAttribute('data-lock-scroll') !== null){
            body.classList.add('scroll-lock')
            html.classList.add('scroll-lock')
            body.style.marginRight = `${differenceWidth}px`;
            header.style.width = `calc(100% - ${differenceWidth}px)`;
        }
        if (!event.target) {
            elem = document.getElementById(event);
            link = document.querySelector(`[data-drop-toggle=${event}]`);
            setting.container = elem;
        } else {
            if (event.target == body) {
                return
            } else {
                if (event.target.getAttribute('data-drop-toggle') == null) {
                    setting.container = document.getElementById(event.target.parentNode.dataset.dropToggle);
                    link = event.target.parentNode;
                } else {
                    setting.container = document.getElementById(event.target.dataset.dropToggle);
                    link = event.target;
                }
            }
        }
        setting.container.isOpen = true;
        setting.container.isAnim = true;
        setting.container.classList.add('is-open');
        link.classList.add('is-open');
        setTimeout(function() {
            setting.container.isAnim = false;
        }, 640);
        if (event.target.getAttribute('data-form') !== null) {
            if (setting.container.querySelector('input[type="text"]')){
                setTimeout(()=> {
                    setting.container.querySelector('input[type="text"]').focus();
                }, 640)
            } else if (setting.container.querySelector('input[type="email"]')){
                setTimeout(()=> {
                    setting.container.querySelector('input[type="email"]').focus();
                }, 640)
            } else if (setting.container.querySelector('input[type="number"]')){
                setTimeout(()=> {
                    setting.container.querySelector('input[type="number"]').focus();
                }, 640)
            }
        }
    }

    function hide(event) {
        let elem, link;
        if (!event.target) {
            elem = document.getElementById(event);
            link = document.querySelector(`[data-drop-toggle=${event}]`);
            setting.container = elem;
        } else {
            if (event.target == body) {
                return
            } else {
                if (event.target.getAttribute('data-drop-toggle') == null) {
                    setting.container = document.getElementById(event.target.parentNode.dataset.dropToggle);
                    link = event.target.parentNode;
                } else {
                    setting.container = document.getElementById(event.target.dataset.dropToggle);
                    link = event.target;
                }
            }
        }
        setting.container.classList.add('is-out');
        link.classList.add('is-out');
        setting.container.isAnim = true;
        setTimeout(function () {
            setting.container.classList.remove('is-out');
            setting.container.isOpen = false;
            setting.container.classList.remove('is-open');
            link.classList.remove('is-open');
            link.classList.remove('is-out');
            setting.container.isAnim = false;
            body.classList.remove('scroll-lock');
            html.classList.remove('scroll-lock');
            body.style.marginRight = 0;
            header.style.width = '100%';
        }, 640)
    }

    function toggle(event) {
        event.stopPropagation();
        event.preventDefault();
        if (!event.target) {
            elem = document.getElementById(event);
            link = document.querySelector(`[data-drop-toggle=${event}]`);
            setting.container = elem;
        } else {
            if (event.target.getAttribute('data-drop-toggle') == null) {
                setting.container = document.getElementById(event.target.parentNode.dataset.dropToggle);
            } else {
                setting.container = document.getElementById(event.target.dataset.dropToggle);
            }
        }
        if (setting.container.isAnim == undefined || setting.container.isAnim == null) {setting.container.isAnim = false};
        if (setting.container.isAnim) {return}

        if (setting.container.isOpen) {
            hide(event);
        } else {
            show(event);
        }
    }

    window.addEventListener('keyup', function (e) {
        if (e.keyCode == '27') {
            hide(event);
        }
    });

    delegate('[data-drop-toggle]', 'click', function(e) {
        toggle.call(e.delegateTarget, e);
    }, false);

    window.hide = hide;
    API.hide = hide;
    return API;
}