export default function fileLoad(container) {

    if (!container) {return}

    const file = container.querySelector('.js-input-file');
    const filenamePlaceholder = container.querySelector('.js-file-name');
    const removeLink = container.querySelector('.js-remove-file');

    file.addEventListener('change', (e) => {
        let filename = e.target.value.replace(/^.*[\\/]/, '')
        container.classList.add('file-loaded');
        filenamePlaceholder.innerHTML = filename;
    });

    removeLink.addEventListener('click', () => {
        file.value = '';
        filenamePlaceholder.innerHTML = '';
        container.classList.remove('file-loaded');
    })
}