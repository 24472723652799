import axios from 'axios';

export default function validate(container) {

    var form = container.querySelector('[data-form-validate]'),
        inputs = form.querySelectorAll('[data-input-required]'),
        messageBlock = container.querySelector('[data-form-message-block]'),
        formSubmit = form.querySelector('[data-form-submit]'),
        formCloses = Array.from(container.querySelectorAll('[data-form-close]')),
        inputsArr = [],
        file = form.querySelector('.js-input-file'),
        fileName = form.querySelector('.js-file-name');
    for (let i = 0; i < inputs.length; i++) {
        let inputsItem = {
            item: inputs[i],
            ready: false,
            dirty: false,
            name: inputs[i].name
        };
        if (inputs[i].type == "checkbox" && inputs[i].checked) {
            inputsItem.ready = true
        }
        if (inputs[i].type == "text") {
            inputsItem.ready = /^[а-яА-Яa-zA-Z0-9\s]{2,200}$/.test(inputs[i].value);
        }
        inputsArr.push(inputsItem);
    }

    function checkReady() {
        let readyCheck = true;
        for (let i = 0; i < inputsArr.length; i++) {
            readyCheck *= inputsArr[i].ready;
            if (!inputsArr[i].ready && inputsArr[i].dirty) {
                inputsArr[i].item.classList.add('is-error');
                inputsArr[i].item.classList.remove('is-success');
            } else if (inputsArr[i].ready && inputsArr[i].dirty) {
                inputsArr[i].item.classList.remove('is-error');
                inputsArr[i].item.classList.add('is-success');
            } else {
                inputsArr[i].item.classList.remove('is-error');
            }
        }

        readyCheck ? formSubmit.classList.remove('is-disabled') : formSubmit.classList.add('is-disabled');

        return readyCheck;
	}

	function sendMetric(target) {
		if(typeof ym === 'function'){
			ym(71145481,'reachGoal', target);
		}
		if(typeof gtag === 'function'){
			gtag('event', target, {});
		}
    }

    function showMessage(payload) {
        let modalText = messageBlock.querySelector('.modal-text');
        let modalBtn = messageBlock.querySelector('.js-hide-ask');
        messageBlock.classList.add('is-open');
        if (payload) {
            modalText.innerHTML = payload;
        } else {
            modalText.innerHTML = 'Ошибка соединения с сервером. Попробуйте обновить страницу';
        }
        modalBtn.addEventListener('click', function() {
            messageBlock.classList.add('is-out');
            setTimeout(function() {
                messageBlock.classList.remove('is-out');
                messageBlock.classList.remove('is-open');
            }, 640)
        });
    }

    function makeAllDirty() {
        for (let i = 0; i < inputsArr.length; i++) {
            inputsArr[i].dirty = true;
        }
    }

    function clearAll() {
        for (let i = 0; i < inputsArr.length; i++) {
            inputsArr[i].ready = false;
            if (inputsArr[i].item.type == 'hidden') {
                inputsArr[i].ready = true;
            }
            if (inputsArr[i].item.type == 'checkbox') {
                inputsArr[i].item.checked = false;
            }
            inputsArr[i].dirty = false;
            inputsArr[i].item.value = '';
            inputsArr[i].item.classList.remove('is-fill');
            inputsArr[i].item.classList.remove('is-error');
            inputsArr[i].item.classList.remove('is-success');
        }
        if (file) {
            file.value = '';
            fileName.innerHTML = '';
            form.classList.remove('file-loaded');
        }
    }

    for (let i = 0; i < inputsArr.length; i++) {
        inputsArr[i].item.addEventListener('blur', function (e) {
            if (inputsArr[i].item.type == 'tel') {
                (e.target.value.length == 18) ? inputsArr[i].ready = true: inputsArr[i].ready = false;
            } else if (inputsArr[i].item.type == 'email') {
                /^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i.test(e.target
                    .value) ? inputsArr[i].ready = true : inputsArr[i].ready = false;
            } else {
                e.target.value.length > 1 ? inputsArr[i].ready = true : inputsArr[i]
                    .ready = false;
            }
            checkReady();
        })
        inputsArr[i].item.addEventListener('focus', (e) => {
            inputsArr[i].dirty = true;
        })
    }

    for (let i = 0; i < inputsArr.length; i++) {
        if (inputsArr[i].item.type == 'checkbox') {
            inputsArr[i].item.addEventListener('change', (e) => {
                e.target.checked ? inputsArr[i].ready = true : inputsArr[i].ready = false;
                inputsArr[i].dirty = true;
                checkReady();
            })
        }
    }

    for (let i = 0; i < inputsArr.length; i++) {
        if (inputsArr[i].item.type == 'radio') {
            inputsArr[i].item.addEventListener('change', (e) => {
                for (let i = 0; i < inputsArr.length; i++) {
                    if (inputsArr[i].name == e.target.name) {
                        inputsArr[i].ready = true;
                    }
                }
                checkReady();
            })
        }
    }

    if (formCloses) {
        formCloses.forEach(close => {
            close.addEventListener('click', (e) => {
                e.preventDefault();
                clearAll();
            })
        });
    }

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        makeAllDirty();
        if (!checkReady()) {
            return
        }
		let needReload = false;
        if (e.target.dataset.reload !== null && e.target.dataset.reload !== undefined) {
            needReload = true;
		}
		let strMetricTarget = '';
		if (e.target.dataset.target !== null && e.target.dataset.target !== undefined) {
            strMetricTarget = e.target.dataset.target;
		}
		if (e.target.dataset.reload !== null && e.target.dataset.reload !== undefined) {
            needReload = true;
        }
        e.target.classList.add('is-load');

        const headers = {
            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
        };

        let formData = new FormData(e.target);
        axios.post(e.target.action, formData, {headers})
        .then(res => {
			if(
				res.data.success
				&& (strMetricTarget != '')
			){
				sendMetric(strMetricTarget);
			}
            if (needReload) {
                if (res.data.success) {
					if(res.data.redirect_url === undefined){
						location.reload();
					}else{
						location.href = res.data.redirect_url;
					}
                } else {
                    e.target.classList.remove('is-load');
                    if (res.data.message) {
                        showMessage(res.data.message);
                    }
                }
            } else {
                e.target.classList.remove('is-load');
                if (res.data.success) {
                    if (res.data.message) {
                        showMessage(res.data["message"]);
                    }
                }
            }
        })
        .catch(res => {
			e.target.classList.remove('is-load');
			console.log(res)
            if (res.data.message) {
                showMessage(res.data.message);
            }
        })
        .finally(res => {
            if (!needReload) {
                e.target.classList.remove('is-load');
                clearAll();
            }
            // hide(formCloses[0]?.dataset.dropToggle);
        });
    })
}